

.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px 0;
}

.home-category-slide {
    height: 36px;
    margin: 50px 0;
}
.home-artist-slide {
    margin: 50px 0;
}

.home-artist-slide {
    margin: 50px 0;
}

.detail-main{
    display: flex;
    width: 100%;
    margin-top: 60px;
}
.detail-main .detail-left{
    display: flex;
    width: 45%;   
}
.detail-main .detail-right{
    display: flex;
    width: 55%; 
}